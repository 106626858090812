<template>
  <div class="flex flex-col items-center gap-[4px] mobile:w-[60px] ">
    <div
        class="w-[60px] h-[60px] rounded-[60px]  mobile:w-[40px]  mobile:h-[40px] bg-center bg-no-repeat bg-[length:32px_32px] mobile:bg-[length:24px]"
        :style="{backgroundColor: active ? '#A8221A' : '#CBCBCB', backgroundImage: `url('${image}')`}"
    />

    <p
      class="m-0 text-[16px] leading-[28px] mobile:text-[14px] mobile:leading-[24px]"
      :style="{color: active ? '#A8221A' : '#CBCBCB', fontFamily: active ? 'Suit-Bold' : 'Suit-Regular'}"
    >{{title}}</p>
  </div>

</template>
<script>
export default {
  props: {
    'image': {
      default: () => '',
      type: String
    },
    'title': {
      default: () => '',
      type: String
    },
    'active': {
      default: () => false,
      type: Boolean
    },

  }
}
</script>